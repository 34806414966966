import type { LucideIcon } from 'lucide-vue-next'
import type { DropDownOption, ILocaleCode } from '.'

export interface IUserLoginAPI {
	/**
	 * User's password
	 */
	pwd: string
	/**
	 * User's email or username
	 */
	login: string
}

export interface IUserPremiumAPI {
	active: boolean
	expiration_date: string
}

export interface IUser {
	/**
	 * User's username
	 */
	username: string
	/**
	 * User's email address
	 */
	email: string
	/**
	 * Flag indicating whether the user wants to receive updates
	 */
	keep_updated: boolean
	/**
	 * User's language
	 */
	lang: ILocaleCode
	/**
	 * Indicates if the user have a premium membership
	 */
	premium: IUserPremiumAPI
}

/**
 * @description User Registration interface
 */
export interface IUserRegistration {
	/**
	 * Flag indicating whether the user wants to receive updates
	 */
	keep_updated: boolean

	/**
	 * User's language
	 */
	lang: ILocaleCode

	/**
	 * User's email address
	 */
	email: string
}

/**
 * @description User Registration interface (client-side)
 */
export interface IUserUpdate extends Omit<IUser, 'email' | 'premium'> {
	/**
	 * User's password
	 */
	pwd: string
}

/**
 * @description User Registration interface (server-side)
 */
export interface IUserUpdateAPI {
	/**
	 * User's password
	 */
	pwd?: string

	/**
	 * User data excluding the email field.
	 */
	user: Partial<Omit<IUser, 'email'>>
}

/**
 * @description User Device info
 */
export interface IUserDeviceAPI {
	user_agent: string
	last_seen: string
	id: number
	device_id: string
}

/**
 * Represents the different types of user modals.
 */
export enum EUserModalType {
	EXIT = -1,
	LOGIN = 0,
	REGISTER_SUCCESS = 2,
	FORGOT_PASSWORD = 3,
	DASHBOARD = 4,
	LOCKED_FEATURE = 5,
	CHECKOUT_PREMIUM = 6,
	PURCHASE_HISTORY = 7,
	AUTO_SCROLL = 8,
	DELETE_FAVORITES = 9,
}

/**
 * Represents the title and icon of a user modal.
 */
export interface IUserModalTitle {
	title: string
	icon: string | LucideIcon
}
