<script setup lang="ts">
import { defineAsyncComponent, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/authStore'
import { useUserModalStore } from '@/stores/userModalStore'
import { EUserModalType } from '@/types'
import DeviceFingerprint from '@/utils/deviceFingerprint'
import Menu from '@/components/Menu.vue'

const route = useRoute()
const BottomMenu = defineAsyncComponent(() => import('@/components/BottomMenu.vue'))
const UserModal = defineAsyncComponent(() => import('@/components/user/UserModal.vue'))
const CookieBanner = defineAsyncComponent(() => import('@/components/CookieBanner.vue'))
const authStore = useAuthStore()
const userModalStore = useUserModalStore()

const { user } = storeToRefs(authStore)

onMounted(() => {
	document.addEventListener('contextmenu', (event) => {
		// Check if the user is not premium
		if (!user.value.premium.active) {
			// Always prevent right-click context menu
			event.preventDefault()

			// Check if the target is an image
			if (event.target instanceof HTMLImageElement) {
				userModalStore.displayUserModal(EUserModalType.LOCKED_FEATURE, { messageI18n: 'locked_feature.message_download', doNotShow: false })
			}
		}
	})
})

DeviceFingerprint.create()
</script>

<template>
	<div class="relative">
		<Menu />
		<!--     <Achievements class="mt-[100px]" /> -->
		<router-view />
		<UserModal />
		<CookieBanner v-if="route.path !== '/home'" />
		<BottomMenu />
	</div>
</template>

<style lang="scss">

</style>
