<script setup lang="ts">
import { computed, defineAsyncComponent, onBeforeUnmount, ref, watch, watchEffect } from 'vue'
import { BookHeart, Check, Cookie, Images, Info, LogIn, LogOut, ShoppingBag, User, WandSparkles } from 'lucide-vue-next'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { addYears } from 'date-fns'
import Menu from 'primevue/menu'
import type { LucideIcon } from 'lucide-vue-next'
import { useSettingStore } from '@/stores/settingStore'
import { EUserModalType, type ILocaleCode } from '@/types'
import Tools from '@/utils/tools'
import { useAuthStore } from '@/stores/authStore'
import router from '@/router'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useUserModalStore } from '@/stores/userModalStore'
import { useFavorites } from '@/composables/useFavorites'
import { useFilters } from '@/composables/useFilters'
import { useResizeListener } from '@/composables/useResizeListener'

const LocaleSetter = defineAsyncComponent(() => import('@/components/LocaleSetter.vue'))

const { t } = useI18n()
const menu = ref()
const settingsStore = useSettingStore()
const authStore = useAuthStore()
const userModalStore = useUserModalStore()
const { routeToFavorites } = useFavorites()
const { togglePanel } = useFilters()
const { user } = storeToRefs(authStore)
const { displayedModal } = storeToRefs(userModalStore)
const { computedScreenWidth, computedScreenHeight } = useResizeListener()

const discordLink = ref<string>(import.meta.env.VITE_DISCORD_INVITE_LINK ?? '')
const locale = ref<ILocaleCode>(settingsStore.getLocale().code)
const { scrollDirection, scrollPosition } = useScrollDirectionEvent()

const route = useRoute()
const showFilterBtn = ref<boolean>(false)
const showMobileMenu = ref<boolean>(false)
showFilterBtn.value = route.path === '/'

watch(
	() => route.fullPath,
	() => {
		showFilterBtn.value = route.path === '/'
	},
)

const isMobile = computed(() => {
	return computedScreenWidth.value < 768 || computedScreenHeight.value < 600
})

watch(() => locale.value, async (code: ILocaleCode) => {
	if (authStore.isAuth()) {
		await authStore.updateUser({ user: { lang: code } })
	}
	Tools.setCookie('lang', code, addYears(new Date(), 1), `.${import.meta.env.VITE_DOMAIN}`, '/')
	window.location.replace(`${import.meta.env.VITE_PROTOCOL}://${code}.${import.meta.env.VITE_DOMAIN}${router.currentRoute.value.path}`)
})

function resizeCb() {
	showMobileMenu.value = false
	menu.value?.hide()
}
window.addEventListener('resize', resizeCb)

onBeforeUnmount(() => {
	window.removeEventListener('resize', resizeCb)
})

watchEffect(() => {
	if (scrollPosition.value) {
		menu.value?.hide()
	}
})

async function logout() {
	await authStore.logout()
	userModalStore.hideUserModal()
	displayedModal.value = EUserModalType.EXIT
	router.push({
		path: '/',
		query: route.query,
	})
}

interface MenuItem {
	label: string // The label text
	icon?: string | LucideIcon // The icon name or component
	showWhenAuth?: boolean // Whether the item should be shown based on authentication status
	action?: () => void // An optional action to perform when the item is clicked
	externalLink?: string // An external link if the item is a link
	route?: string // The route to navigate to if the item is a navigation link
}

interface MenuGroup {
	label: string // The main label text for the group
	items: MenuItem[] // An array of sub-items
}
const items = computed<MenuGroup[]>(() => {
	const isAuthenticated = authStore.isAuth()

	const items: MenuGroup[] = [
		{
			label: t('menu.profile'),
			items: [
				{
					label: t('menu.login'),
					icon: 'pi pi-sign-in',
					showWhenAuth: false,
					action: () => userModalStore.displayUserModal(EUserModalType.LOGIN),
				},
				{
					label: t('menu.settings'),
					icon: 'pi pi-cog',
					showWhenAuth: true,
					action: () => userModalStore.displayUserModal(EUserModalType.DASHBOARD),
				},
				{
					label: t('menu.logout'),
					icon: 'pi pi-sign-out',
					showWhenAuth: true,
					action: logout,
				},
			],
		},
		{
			label: t('menu.follow_us'),
			items: [
				{ label: 'Discord', icon: 'pi pi-discord', externalLink: discordLink.value },
				{ label: 'Reddit', icon: 'pi pi-reddit', externalLink: 'https://www.reddit.com/r/FapFapAI/' },
				{ label: 'x', icon: 'pi pi-twitter', externalLink: 'https://x.com/FapFap_ai' },
			],
		},
		{
			label: t('menu.more'),
			items: [
				{ label: t('menu.about'), icon: 'pi pi-info-circle', route: '/about' },
			],
		},
	]

	return items
		.map(item => ({
			...item,
			items: item.items.filter(subItem =>
				'showWhenAuth' in subItem ? subItem.showWhenAuth === isAuthenticated : true,
			),
		}))
		.filter(item => item.items.length > 0)
})

function toggle(event: any) {
	if (isMobile.value) {
		showMobileMenu.value = !showMobileMenu.value
		document.body.classList[showMobileMenu.value ? 'add' : 'remove']('overflow-hidden')
	}
	else if (menu.value) {
		menu.value.toggle(event)
	}
}

watch(() => showMobileMenu.value, (state: boolean) => {
	document.body.classList[state ? 'add' : 'remove']('overflow-hidden')
})
</script>

<template>
	<div class="select-none fixed w-screen bg-gray-900 py-2 px-3 flex items-center justify-between z-[8] h-[50px]" :class="scrollDirection === 'up' && scrollPosition > 400 ? 'move-up-menu ' : 'move-down-menu '">
		<div>
			<h1 class="text-lg xs:text-xl sm:text-2xl font-extrabold select-none m-0">
				FapFap<span class="text-orange-500 rounded-md">.ai</span>
			</h1>
		</div>

		<div class="items-center flex">
			<div class="items-center text-sm md:text-base md:flex md:absolute md:left-1/2 md:transform md:-translate-x-1/2 md:top-1/2 md:-translate-y-1/2 hidden">
				<Button class="rounded-md h-[36px] gap-1 items-center" :disabled="!showFilterBtn" @click="togglePanel">
					<template #default>
						<WandSparkles :size="18" />
						<span>{{ t('filters.title') }}</span>
					</template>
				</Button>
				<Divider layout="vertical" class="" />
				<router-link to="/" class="no-underline">
					<span class="top-menu-item w-full flex items-center gap-2 px-3 py-[6px] hover:bg-purple-600 rounded-md cursor-pointer transform-color duration-300 text-white">
						<Images :size="16" />
						<span>{{ t('menu.gallery') }}</span>
					</span>
				</router-link>
				<router-link to="/favorites" class="no-underline ml-1" @click.prevent="routeToFavorites">
					<span class="top-menu-item w-full flex items-center gap-2 px-3 py-[6px] hover:bg-purple-600 rounded-md cursor-pointer transform-color duration-300 text-white">
						<BookHeart :size="16" />
						<span>{{ t('menu.favorites') }}</span>
					</span>
				</router-link>
			</div>
			<div class="flex gap-3 items-center mr-2">
				<div class="w-fit">
					<div class="text-sm md:text-base cursor-pointer px-2 w-[40px] h-[2.1rem] flex justify-center items-center gap-1 border-[1px] rounded-md border-orange-400 border-solid hover:bg-gray-700 hover:border-orange-500 transform duration-300" aria-haspopup="true" aria-controls="overlay-menu-desktop" @click="toggle">
						<User :size="20" />
					</div>
					<Menu v-if="!isMobile" id="overlay-menu-desktop" ref="menu" :model="items as any" :popup="true" class="z-[10]">
						<template #item="{ item, props }">
							<router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
								<a :href="href" v-bind="props.action" @click="navigate">
									<span :class="item.icon" />
									<span class="ml-2">{{ item.label }}</span>
								</a>
							</router-link>
							<a v-else-if="item.externalLink" :href="item.externalLink" v-bind="props.action" target="_SEJ">
								<span :class="item.icon" />
								<span class="ml-2">{{ item.label }}</span>
							</a>
							<a v-else :target="item.target" v-bind="props.action" @click="item.action">
								<i v-if="typeof item.icon === 'string'" :class="item.icon" />
								<component :is="item.icon" v-else :size="18" />
								<span class="ml-2">{{ item.label }}</span>
							</a>
						</template>
						<template #end>
							<Divider class="my-2 w-10/12 m-auto" />
							<LocaleSetter v-model="locale" class="w-full !border-none flex !justify-start" />
						</template>
					</Menu>
					<div v-else v-show="showMobileMenu" id="overlay-menu-mobile" class="z-[10] top-0 left-0 bg-gray-900 fixed w-screen h-screen overflow-hidden overflow-y-auto flex flex-col items-center gap-6 py-4">
						<Button
							icon="pi pi-times" rounded text aria-label="Filter" severity="help"
							class="z-50 fixed sm:top-[10px] sm:left-[10px] bg-transparent border-none outline-none hover:bg-transparent hover:text-orange-500 top-[4px] left-0 sm:w-10 sm:h-10"
							size="large" @click="toggle" @touchend="toggle"
						/>
						<div class="flex flex-col gap-4 mt-auto mb-auto">
							<template v-for="group in items" :key="group.label">
								<template v-for="item in group.items" :key="item.label">
									<router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
										<a :href="href" class="flex items-center gap-1 no-underline text-white hover:bg-purple-600 rounded-md p-2 uppercase" @click="navigate; showMobileMenu = false">
											<span :class="item.icon" class="text-2xl" />
											<span class="ml-2">{{ item.label }}</span>
										</a>
									</router-link>
									<a v-else-if="!item.externalLink" class="flex items-center gap-1 no-underline text-white hover:bg-purple-600 rounded-md p-2 uppercase" @click="item.action && item.action(); showMobileMenu = false">
										<i v-if="typeof item.icon === 'string'" :class="item.icon" class="text-2xl" />
										<component :is="item.icon" v-else :size="24" />
										<span class="ml-2">{{ item.label }}</span>
									</a>
								</template>
							</template>
						</div>
						<div class="flex gap-8">
							<template v-for="item in items[1].items" :key="item.label">
								<a :href="item.externalLink" target="_SEJ" class="no-underline text-white" @click="showMobileMenu = false">
									<span :class="item.icon" class="text-3xl" />
								</a>
							</template>
						</div>

						<Divider class="my-2 w-10/12 m-auto" />
						<LocaleSetter v-model="locale" class="w-10/12  flex mb-14" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.logo-title {
	font-family: 'Nova Square', sans-serif;
}

.move-up-menu {
	top: -50px;
	transition: top 0.3s ease-in-out;
}

.move-down-menu {
	top: 0px;
	transition: top 0.3s ease-in-out;
}
</style>
