import { defineStore } from 'pinia'
import { nextTick, ref, watch } from 'vue'
import { EUserModalType } from '@/types'

export const useUserModalStore = defineStore('userModalStore', () => {
	const displayedModal = ref<EUserModalType>(EUserModalType.LOGIN)
	const visibility = ref<boolean>(false)
	const modalProps = ref<Record<string, any>>({})
	const blockScroll = ref<boolean>(true)

	function displayUserModal(type: EUserModalType, props: Record<string, any> = {}, blockScrollBody: boolean = true) {
		visibility.value = true
		displayedModal.value = type
		modalProps.value = props
		blockScroll.value = blockScrollBody
	}

	function hideUserModal(): void {
		modalProps.value = {}
		visibility.value = false
	}

	watch(() => visibility.value, async (state: boolean) => {
		if (blockScroll.value) {
			await nextTick()
			document.body.classList[state ? 'add' : 'remove']('overflow-hidden')
		}
		else {
			blockScroll.value = true
			document.body.classList.remove('overflow-hidden')
		}
	})

	return { displayedModal, visibility, modalProps, displayUserModal, hideUserModal }
})
