<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import Toast from 'primevue/toast'
import { onBeforeRouteLeave } from 'vue-router'
import { useGalleryStore } from '@/stores/galleryStore.ts'
import { useSettingStore } from '@/stores/settingStore'
import { useAuthStore } from '@/stores/authStore'
import { IImageGalleryType } from '@/types'
import { useFilters } from '@/composables/useFilters'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useResizeListener } from '@/composables/useResizeListener'
import { useAutoScroll } from '@/composables/useAutoScroll'

const Image = defineAsyncComponent(() => import('@/components/Image.vue'))
const FiltersPanel = defineAsyncComponent(() => import('@/components/filters/FiltersPanel.vue'))
const SpinnerHeart = defineAsyncComponent(() => import('@/components/SpinnerHeart.vue'))

const { stop: stopAutoScroll, setTarget } = useAutoScroll()
const settingStore = useSettingStore()
const imageStore = useGalleryStore()
const { disable } = useScrollDirectionEvent()
const { open: openedFilters } = useFilters()
const { computedScreenWidth } = useResizeListener()

const authStore = useAuthStore()

const intersectEnabled = ref<boolean>(false)
const gallery = ref<HTMLElement>()

const { galleryType, imageFullScreen, galleryScroll } = storeToRefs(settingStore)
const { user } = storeToRefs(authStore)
const { images } = storeToRefs(imageStore)

galleryType.value = IImageGalleryType.GALLERY

function zoomImage(index: number) {
	imageStore.setZoomedImage(index)
	imageFullScreen.value = true
}

onBeforeUnmount(() => {
	if (gallery.value instanceof HTMLElement) {
		galleryScroll.value = openedFilters.value ? gallery.value.scrollTop : window.scrollY
	}
})

onMounted(async () => {
	await nextTick()
	const inter = setInterval(() => {
		if (gallery.value instanceof HTMLElement && gallery.value.scrollHeight >= galleryScroll.value) {
			(openedFilters.value ? gallery.value : window).scrollTo({ top: galleryScroll.value })
			setTarget(openedFilters.value ? gallery.value : window)
			clearInterval(inter)
			intersectEnabled.value = true
		}
	}, 50)
})

watch(() => openedFilters.value, async () => {
	if (computedScreenWidth.value >= 768) {
		disable.value = true
		const windowScroll = window.scrollY
		const containerScroll = gallery.value instanceof HTMLElement ? gallery.value.scrollTop : 0
		await nextTick()

		if (gallery.value instanceof HTMLElement) {
			if (windowScroll) {
				gallery.value.scrollTo({ top: windowScroll })
			}
			else {
				window.scrollTo({ top: containerScroll })
			}
		}
		setTimeout(() => {
			disable.value = false
		}, 0)
	}
})

onBeforeRouteLeave(async () => {
	if (!imageFullScreen.value) {
		stopAutoScroll()
	}
})

watch(() => openedFilters.value, async () => {
	if (openedFilters.value) {
		setTarget(gallery.value as HTMLElement)
	}
	else {
		setTarget(window)
	}
})
</script>

<template>
	<Toast
		:pt="{
			root: {
				class: 'p-custom-toast',
			},
		}"
	/>
	<SpinnerHeart v-if="!imageStore.allImages.length" class="!fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`" />
	<div class="mt-[50px] mb-[5px] w-full flex justify-center h-[calc(100vh-60px)]">
		<div ref="gallery" style="-webkit-overflow-scrolling: touch;" class="w-full flex flex-wrap justify-center relative gap-1" :class="computedScreenWidth >= 768 && openedFilters ? 'overflow-hidden overflow-y-auto' : ''">
			<template v-for="(image, index) in imageStore.allImages" :key="image.uuid">
				<div class="flex justify-center w-full h-auto sm:w-[250px] sm:h-[360px] lg:w-[315px] lg:h-[454px] xl:w-[420px] xl:h-[606px]">
					<Image :data="imageStore.allImages[index]" :index="image.uuid" @on-image-zoom="zoomImage(index)" />
				</div>
			</template>
			<div v-if="intersectEnabled" v-delayed-intersect="imageStore.loadMoreImages" class="w-full h-[10px] mt-[-250px] -z-[1]" />
		</div>
		<FiltersPanel />
	</div>
</template>
