import type { Ref } from 'vue'
import { shallowRef, watch } from 'vue'
import Tools from '@/utils/tools'

export interface IUseAutoScroll {
	start: Ref<boolean>
	speed: Ref<number>
	clockTick: Ref<number>
	stop: () => void
	setTarget: (target: HTMLElement | Window & typeof globalThis) => void
}

const start = shallowRef<boolean>(false)
const speed = shallowRef<number>(0.7)
const speedFS = shallowRef<number>(Tools.map(speed.value, 0.35, 3, 30000, 2000))
const clockTick = shallowRef<number>(1)
let target: HTMLElement | Window & typeof globalThis = window
let animationFrameId: number = 0
let scrollAccumulator: number = 0

let startTs = 0
let lastFrameTime = 0

let firstLoop

watch(() => speed.value, () => {
	speedFS.value = Tools.map(speed.value, 0.35, 3, 30000, 2000)
})

function run() {
	if (start.value) {
		const currentFrameTime = Date.now()
		const deltaTime = currentFrameTime - lastFrameTime
		lastFrameTime = currentFrameTime

		if (!startTs) {
			startTs = currentFrameTime
		}
		else {
			const elapsed = currentFrameTime - startTs
			const tick = Math.floor(Tools.map(elapsed, 0, speedFS.value, 1, 13))
			clockTick.value = tick === 13 ? 1 : tick

			if (elapsed >= speedFS.value) {
				startTs = currentFrameTime
			}
		}

		scrollAccumulator += speed.value * (deltaTime / 16.67)

		if (scrollAccumulator >= 1) {
			target.scrollBy(0, scrollAccumulator)
			scrollAccumulator -= Math.floor(scrollAccumulator)
		}

		animationFrameId = requestAnimationFrame(run)
	}
}

watch(() => start.value, () => {
	if (start.value) {
		lastFrameTime = Date.now()
		run()
	}
	else {
		clockTick.value = 1
		startTs = 0
		scrollAccumulator = 0
		animationFrameId = 0
		cancelAnimationFrame(animationFrameId)
	}
})

function stop() {
	target = window
	start.value = false
}

function setTarget(_target: HTMLElement | Window & typeof globalThis = window) {
	target = _target
}

export function useAutoScroll(): IUseAutoScroll {
	return { start, speed, stop, setTarget, clockTick }
}
