<script setup lang="ts">
import { defineAsyncComponent, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { Crown, Lock } from 'lucide-vue-next'
import ProgressSpinner from 'primevue/progressspinner'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import Card from 'primevue/card'
import Toast from 'primevue/toast'
import { useGalleryStore } from '@/stores/galleryStore'
import Tools from '@/utils/tools'
import LockableVariable from '@/utils/lockableVariable'
import { useCompatibilityStore } from '@/stores/compatibilityStore'
import router from '@/router'
import { useSettingStore } from '@/stores/settingStore'
import { useFavoriteStore } from '@/stores/favoriteStore'
import type { IImageGallery } from '@/types'
import { IImageGalleryType } from '@/types'
import { useAuthStore } from '@/stores/authStore'
import type Services from '@/services'
import { ImageNotFound, UnauthorizedResource } from '@/errors/ImageError'
import { useFilterStore } from '@/stores/filterStore'

// Store is empty in case of a direct link to an image
const props = defineProps<Props>()

const ImageMenu = defineAsyncComponent(() => import('@/components/ImageMenu.vue'))

interface Props {
	uuid: string
}

const services = inject<Services>('services')
const { t } = useI18n()
const authStore = useAuthStore()
const settingStore = useSettingStore()
const filterStore = useFilterStore()
const imageSrc = ref<string>('')
const uuid = ref<string>('')
const imageFilters = ref<Record<string, number>>({})
const isFavorite = ref<boolean>(false)
const markForDeletion = ref<boolean>(false)
const imageElement = ref<HTMLImageElement>()
const galleryStore = useGalleryStore()
const favoriteStore = useFavoriteStore()
const compat = useCompatibilityStore()
const { seed, timestamp, galleryType, imageFullScreen } = storeToRefs(settingStore)
const { user } = storeToRefs(authStore)
const img = new Image()
const loading = ref(true)
const imageStore: typeof galleryStore | typeof favoriteStore = galleryType.value === IImageGalleryType.FAVORITE ? favoriteStore : galleryStore
const { images } = storeToRefs(imageStore)
const directLink = !images.value.length
const unauthorizedAccess = ref<boolean>(false)

let imageLoading: AbortController
let pageOffset: number = 1

const loadNextImage = {
	ArrowDown: () => {
		imageStore.index++
	},
	ArrowUp: () => {
		if (imageStore.index > 0) {
			imageStore.index--
		}
	},

}

function handleKeydown(event: KeyboardEvent) {
	if (event.key === 'Escape') {
		unmountComponent()
	}
	if (imageFullScreen.value && (
		event.key === 'ArrowDown'
		|| event.key === 'ArrowUp'
		|| event.key === 'PageDown'
		|| event.key === 'PageUp'
		|| event.key === ' '
	)) {
		event.preventDefault()
	}

	if (imageFullScreen.value && (
		event.key === 'ArrowDown'
		|| event.key === 'ArrowUp')) {
		loadNextImage[event.key]()
	}
}

function handleWheel(event: WheelEvent) {
	if (imageFullScreen.value) {
		event.preventDefault()
		const dir = event.deltaY > 0 ? 'ArrowDown' : 'ArrowUp'

		loadNextImage[dir]()
	}
}

async function setImageInfo(): Promise<void> {
	try {
		const imageData = await services?.getImageInfo(uuid.value)
		if (imageData) {
			if ('is_favorite' in imageData) {
				isFavorite.value = imageData.is_favorite
			}
			if ('filters' in imageData) {
				imageFilters.value = imageData.filters
			}
		}
		else {
			router.back()
		}
	}
	catch (error: any) {
		if (error instanceof UnauthorizedResource) {
			unauthorizedAccess.value = true
			loading.value = false
		}
		if (error instanceof ImageNotFound) {
			router.push('/404')
		}
	}
}

watch(() => imageStore.index, async (index) => {
	imageLoading?.abort()

	if (directLink && !index) {
		uuid.value = props.uuid ?? ''
		downloadImage(uuid.value)
		history.replaceState({}, '', `/image/${uuid.value}`)
	}
	else {
		imageLoading = loadImage(index)
	}
})

onMounted(async () => {
	window.addEventListener('keydown', handleKeydown)
	uuid.value = props.uuid ?? ''

	if (directLink) {
		await setImageInfo()
		pageOffset = Tools.random(1, await getImageCount())
		imageStore.index = 0
	}
	else {
		// enter this condition after clicking on an image from the main gallery or favorites
		isFavorite.value = images.value[imageStore.index].is_favorite
		imageFilters.value = images.value[imageStore.index].filters
		downloadImage(props.uuid ?? '')
	}
	imageFullScreen.value = true
})

onUnmounted(() => {
	window.removeEventListener('keydown', handleKeydown)
})

function unmountComponent() {
	imageLoading?.abort()
	imageFullScreen.value = false
	if (directLink) {
		router.push('/')
	}
	else {
		history.back()
	}
}

let v: number[] = [0, 0]
let dir: number[] = [0, 0]
const touchIdentifier = new LockableVariable<number>(0)

function resetImagePosition() {
	if (imageElement.value) {
		imageElement.value.style.transform = `translate(0px, 0px)`
		imageElement.value.style.width = ''
		imageElement.value.style.opacity = ''
	}
	v = [0, 0]
	dir = [0, 0]
}

function touchStart(event: TouchEvent) {
	if (imageFullScreen.value && touchIdentifier.isUnlocked()) {
		touchIdentifier.setValue(event.touches.item(0)?.identifier ?? 0).lock()

		resetImagePosition()
		v = [
			event.touches[0].clientX,
			event.touches[0].clientY,
		]
	}
}

function handleTouchMove(event: TouchEvent) {
	event.preventDefault()

	let found = false

	for (let i = 0; i < event.touches.length; i++) {
		const touch: Touch = event.touches[i]

		if (touch.identifier === touchIdentifier.getValue()) {
			found = true
			break
		}
	}
	if (imageFullScreen.value && found && touchIdentifier.isLocked()) {
		const v2 = [
			event.touches[0].clientX,
			event.touches[0].clientY,
		]

		dir = [
			Math.ceil(v2[0] - v[0]),
			Math.ceil(v2[1] - v[1]),
		]
		const length = Math.sqrt(dir[0] ** 2 + dir[1] ** 2)

		if (imageElement.value) {
			imageElement.value.style.transform = `translate(${dir[0]}px, ${dir[1]}px)`
			imageElement.value.style.width = `${Tools.map(length, 0, 500, 100, 0)}%`

			if (length >= 30) {
				imageElement.value.style.opacity = `${Tools.map(length, 30, 100, 1, 0)}`
			}
			else {
				imageElement.value.style.opacity = ''
			}
		}
	}
}

function touchEnd(event: TouchEvent) {
	let found = false

	for (let i = 0; i < event.changedTouches.length; i++) {
		const touch: Touch = event.changedTouches[i]

		if (touch.identifier === touchIdentifier.getValue()) {
			found = true
			break
		}
	}

	if (v[0] !== 0 && found) {
		const length = Math.sqrt(dir[0] ** 2 + dir[1] ** 2)

		if (length >= 100) {
			if (dir[1] > 0) {
				loadNextImage.ArrowUp()
			}
			else {
				loadNextImage.ArrowDown()
			}
		}
		resetImagePosition()
		touchIdentifier.unlock()
	}
}

function loadImage(index: number) {
	loading.value = true
	const abortController = new AbortController()

	const headers = new Headers()
	if (galleryType.value === IImageGalleryType.GALLERY) {
		headers.append('Seed', seed.value.toString())
		headers.append('Timestamp', timestamp.value)
	}
	headers.append('Content-Type', 'application/json')

	const fsDirect = galleryType.value === IImageGalleryType.GALLERY && !directLink

	const body: BodyInit = JSON.stringify(fsDirect ? filterStore.computedFilters : {})
	services?.getGallery(
		index + pageOffset,
		1,
		headers,
		galleryType.value,
		body,
		abortController,
	)
		.then((data: IImageGallery) => {
			if (data.page > data.total) {
				imageStore.index--
			}
			else {
				if ('items' in data) {
					uuid.value = data.items[0].uuid
					isFavorite.value = data.items[0].is_favorite
					imageFilters.value = data.items[0].filters
					history.replaceState({}, '', `/image/${uuid.value}`)
					downloadImage(uuid.value)
				}
			}
		})
		.catch((error) => {
			console.error(error)
		})
	return abortController
}

function downloadImage(imgSrc: string) {
	imageSrc.value = `${import.meta.env.VITE_CLOUDFLARE_URL}/${imgSrc}.webp`

	img.onload = () => {
		loading.value = false
	}

	img.onerror = () => {
		unauthorizedAccess.value = true
		loading.value = false
	}

	img.src = imageSrc.value
}

async function getImageCount(): Promise<number> {
	const data = await services?.getGallery(1, 1, {
		'Content-Type': 'application/json',
		'Seed': seed.value.toString(),
	}, IImageGalleryType.GALLERY)
	if (data) {
		return data.total
	}
	return 0
}
</script>

<template>
	<Toast
		:pt="{
			root: {
				class: 'p-custom-toast-fs',
			},
		}"
	/>
	<div
		v-if="imageFullScreen" id="container" @wheel="handleWheel" @touchstart="touchStart"
		@touchmove="handleTouchMove" @touchend="touchEnd"
	>
		<Button
			icon="pi pi-times" rounded text aria-label="Filter" severity="help"
			class="z-50 fixed sm:top-[10px] sm:left-[10px] bg-transparent border-none outline-none hover:bg-transparent hover:text-orange-500 top-[4px] left-0 sm:w-10 sm:h-10"
			size="large" @click="unmountComponent" @touchend="unmountComponent"
		/>

		<div class="image-container">
			<ProgressSpinner v-if="loading && !unauthorizedAccess" />
			<img v-else-if="!loading && !unauthorizedAccess" ref="imageElement" :src="imageSrc" alt="Zoomed Image" class="w-full h-auto max-h-full object-contain z-[14]" :class="markForDeletion ? 'opacity-50' : 'opacity-100'">
			<Card v-else-if="!user.premium.active" class="bg-gray-900 shadow-md shadow-black/50 w-[30rem] ml-2 mr-2 text-sm md:text-base">
				<template #title>
					<div class="flex items-center gap-2">
						<Lock :size="18" /> {{ t(`notification.unauthorized_resource.title`) }}
					</div>
				</template>
				<template #content>
					<p class="m-0 p-1">
						{{ t(`notification.unauthorized_resource.message`) }}
					</p>
				</template>
				<template #footer>
					<router-link to="/premium" target="_blank" class="w-full xs:grow">
						<Button class="w-full xs:grow">
							<template #default>
								<div class="flex gap-1 items-center justify-center w-full">
									<Crown :size="18" /> {{ t('menu.go_premium') }}
								</div>
							</template>
						</Button>
					</router-link>
				</template>
			</Card>
		</div>

		<div
			v-if="!compat.mobileDevice && !unauthorizedAccess"
			class="md:flex hidden flex-col justify-center items-center fixed right-[10px] top-1/2 transform -translate-y-1/2 rounded-[6px] w-12 bg-gray-800 overflow-hidden z-[15] border-2 border-gray-700 border-solid"
		>
			<span
				class="w-full flex justify-center items-center h-12 hover:bg-orange-500 [&_i]:hover:text-black cursor-pointer transform duration-300"
				@click="loadNextImage.ArrowUp"
			>
				<i class="pi pi-chevron-up text-base md:text-lg" size="large" @touchend="loadNextImage.ArrowUp" />
			</span>
			<span class="w-full h-[2px] bg-gray-700" />
			<span
				class="w-full flex justify-center items-center h-12 hover:bg-orange-500 [&_i]:hover:text-black cursor-pointer transform duration-300"
				@click="loadNextImage.ArrowDown"
			>
				<i class="pi pi-chevron-down text-base md:text-lg" @touchend="loadNextImage.ArrowDown" />
			</span>
		</div>
		<ImageMenu v-if="!unauthorizedAccess" v-model:isFavorite="isFavorite" :index="imageStore.index" :uuid="uuid" :show-menu="true" :filters="imageFilters" class-menu="image-zoomed-menu" :vertical="true" @next-image="loadNextImage.ArrowDown" @update:mark-for-deletion="(state: boolean) => markForDeletion = state" @exit-full-screen="unmountComponent" />
	</div>
</template>

<style>
.image-zoomed-menu {
	@apply bottom-0 right-0 z-[14];
}
</style>

<style scoped lang="scss">
#container {
	@apply fixed flex justify-center items-center flex-col top-0 left-0 w-screen h-screen z-[13] bg-gray-950;
}

.image-container {
	@apply w-screen h-screen flex justify-center items-center overflow-hidden;
}
</style>
